<template>
  <div :id="`${compId}_unsolved_export_popup`">
    <win-layer-pop class="unsolved_export_popup">
      <component
        v-if="customComponent"
        :is="customComponent"
        :ok-hidden="isHidden"
        :is-show-pop="isShowPop"
        :freight-bill-info="freightBillInfo"
        :detail="detail"
        :parent-info="popupParams"
        @close="commonClosePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import calcIssue from '@/api/rest/settle/calcIssue'
import blPayment from '@/api/rest/trans/blPayment'
import doPayment from '@/api/rest/trans/do'
import transCommon from '@/api/rest/trans/transCommon'
import FreeTimeApi from '@/api/rest/settle/freeTime'
import commons from '@/api/services/commons'
import fare from '@/api/rest/trans/fare'
import exportSvc from '@/api/rest/trans/exportSvc'

export default {
  name: 'CommonUnsolved',
  components: {
    FareRegisReqPop: () => import('@/pages/trans/popup/FareRegisReqPop'), // 운임 등록 요청 팝업
    FreightPop: () => import('@/pages/settle/popup/CalcIssueFreightPop'), // 운임 수정 요청
    FreightBillPop: () => import('@/components/common/FreightBillPop'), // 운임 수납, 계산서 발행
    BLPaymentPop: () => import('@/pages/trans/popup/BLPaymentPop'),
    BLPaymentEtcPop: () => import('@/pages/trans/popup/BLPaymentEtcPop'),
    DORequestEtcPop: () => import('@/pages/trans/popup/DORequestEtcPop'),
    DOPaymentPop: () => import('@/pages/trans/popup/DOPaymentPop'),
    BlInvoiceFreightPop: () => import('@/pages/pdf/popup/BlInvoiceFreightPop'),
    BlProformaInvoiceIdPop: () => import('@/pages/pdf/popup/BlProformaInvoiceIdPop'),
    CombineInvoicePop: () => import('@/pages/pdf/popup/CombineInvoicePop'), // combine Invoice
    DeliveryOrderPop: () => import('@/pages/pdf/popup/DeliveryOrderPop'),
    DemInvoiceMultiPop: () => import('@/pages/settle/popup/DemInvoiceMultiPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    ProformaInvoicePop: () => import('@/pages/settle/popup/ProformaInvoicePop'),
    OriginalBLReIssuePop: () => import('@/pages/trans/popup/OriginalBLReIssuePop') // O B/L 재발행 팝업
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      compId: this.$uuid.v1(),
      customComponent: null,
      isHidden: true,
      isShowPop: false,
      freightBillInfo: [],
      doJpData: [],
      detail: {},
      popupParams: {},
      userDmdInfo: null,
      userDmdCatCd: 'R',
      freeTimeData: [],
      nextArrBlNo: null,
      nextEiCatCd: null,
      nextParam: null,
      nextData: null,
      arrSrdBlNo: []
    }
  },
  computed: {
    ...rootComputed
  },
  async created () {
    if (this.auth.login) {
      await this.$store.dispatch('settle/saveUserDmdCatCd')
      this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
    }
  },
  methods: {
    /*
     * 화주 구분 정보 조회
     */
    async getUserDmd () {
      if (this.userDmdInfo !== null) {
        return this.userDmdInfo
      }

      await calcIssue.getUserDmd({})
        .then(async response => {
          if (response.data) {
            this.userDmdInfo = response.data
          }
        })

      return this.userDmdInfo
    },
    async openFreightPop (arrBlNo, btnType, eiCatCd, dpId = '', invNo = '') {
      let info
      let blNoList = []
      arrBlNo.forEach(blNo => {
        blNoList.push({
            blNo: blNo,
            payCstNo: '',
            eiCatCd: eiCatCd
          })
      })
      if (blNoList.length === 0) {
        return
      }
      await calcIssue.checkBl(blNoList)
        .then(async response => {
          console.log('BL Checked > ', response)
          info = response.data
        })

      if (info.result !== 'N') {
        if (info.result === '01') {
          this.openAlert(this.$t('msg.DOIssue.001') + ` (${info.blNo})`)
        } else {
          this.openAlert(this.$t('msg.DOIssue.002') + ` (${info.blNo}:${info.result})`)
        }
        return
      }

      const userDmdInfo = await this.getUserDmd()
      let freightMap

      await calcIssue.getBlFreight({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
        .then(async response => {
          freightMap = response.data
        })

      if (freightMap === null || freightMap === undefined) {
        freightMap = {}
      }

      let blList = []

      arrBlNo.forEach(blNo => {
        const list = freightMap[blNo]
        console.log(list)
        if (list) {
          list.forEach(bl => {
            if (bl.occrCurCd === 'KRW') {
              bl.krw = bl.occrAmt
            } else if (bl.occrCurCd === 'USD') {
              //
            } else {
              bl.krw = (parseInt(bl.occrAmt) * parseFloat(bl.exRate)).toString()
              bl.occrCurCd = 'KRW'
            }

            if (bl.usdAmt !== undefined && bl.usdAmt !== '' && bl.usdAmt !== '0') {
              blList.push({
                blNo: blNo,
                krw: 0,
                usd: parseInt(bl.usdAmt),
                exRate: parseFloat(bl.usdExRate),
                currency: 'KRW+USD',
                obrdDt: '', //TODO
                eiCatCd: eiCatCd, // O: 수출, I: 수입
                cntrList: []
              })
            }
            if (bl.krwAmt !== undefined && bl.krwAmt !== '' && bl.krwAmt !== '0') {
              blList.push({
                blNo: blNo,
                krw: parseInt(bl.krwAmt),
                usd: 0,
                exRate: 1,
                currency: 'KRW',
                obrdDt: '', //TODO
                eiCatCd: eiCatCd, // O: 수출, I: 수입
                cntrList: []
              })
            }
          })
        }
      })

      blList = this.removeDuplicatedBlList(blList)
console.log('########################################################', blList)
      this.freightBillInfo = {
        dpId: dpId,
        dmdCatCd: eiCatCd === 'O' ? userDmdInfo.etDmdCatCd : userDmdInfo.imDmdCatCd, // 화주 구분값(etDmdCatCd: 수출, imDmdCatCd: 수입)
        //dmdCatCd: 'R', //화주구분 : 말화주(M), 청구화주(C), 영수화주(R)
        btnType: btnType, //FREIGHT: 운임수납, TAX_INVOICE: 계산서발행, BL: BL 발행, DO: DO발행
        hasNotPaid: true, //미정산 금액 존재여부
        hasNotTaxInvoice: false, //세금계산서 미발행 건 존재 여부
        blList: blList,
        invNo: invNo
      }
      console.log('-----------------------------')
      console.log(this.freightBillInfo)
      console.log('-----------------------------')
      this.commonOpenPopup('FreightBillPop')
    },
    removeDuplicatedBlList (blList) {
      let res = []
      let isExist = false
      for (let bl of blList) {
        isExist = false
        for (let r of res) {
          if (r.blNo === bl.blNo) {
            isExist = true
            if (bl.currency === 'KRW') {
              r.krw += bl.krw
            } else if (bl.currency === 'KRW+USD') {
              r.usd += bl.usd
              r.exRate = bl.exRate
              r.currency = 'KRW+USD'
            }
            break
          }
        }
        if (!isExist) {
          res.push(bl)
        }
      }

      return res
    },
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg, closeFun = this.closeFun) {
      this.clearPopupParams()

      let obj = {
        alertType: 'simple',
        onClose: closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    freightPopCall (blNo) {
      this.clearPopupParams()

      this.detail = {
        blNo: blNo,
        remarkCd: ''
      }
      this.commonOpenPopup('FreightPop')
    },
    async fareRegisReqPopCall (blNo) {
      this.clearPopupParams()

      this.popupParams = {
        blNo: blNo,
        index: '',
        invRno: '',
        rtnCode: ''
      }

      await this.commonOpenPopup('FareRegisReqPop')
    },
    async openInvoice (arrBlNo, eiCatCd) {
      let list = []

      await calcIssue.getBlInvoiceData({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
        .then(async response => {
          list = response.data
        })

      const tmpData = []
      const issBlData = []

      list.forEach(vo => {
        if (vo.invIssNo !== undefined && vo.invIssNo !== '') {
          tmpData.push({
            invIssNo: vo.invIssNo,
            to: '',
            remark: '',
            filter: 'BL',
            blNo: vo.blNo,
            esign: ''
          })
        } else if (vo.invRno !== undefined && vo.invRno !== '') {
          issBlData.push({
            blNo: vo.blNo,
            invRno: vo.invRno,
            index: vo.reqCnt,
            mainYn: 'N'
          })
        }
      })

      const blInvoiceParam = {
        asLangCd: this.auth.serviceLang,
        inBound: eiCatCd,
        blList: []
      }

      const invoiceParam = {
        items: []
      }

      const cstCd = this.auth.cstCd
      let componentName

      if (cstCd === 'ID') {
        // 인도네시아
        componentName = 'BlProformaInvoiceIdPop'
        if (tmpData.length === 0) {
          this.openAlert(this.$t('msg.DOIssue.003'))
          return
        }
        invoiceParam.items = tmpData
      } else {
        // 일반
        console.log(issBlData)
        if (issBlData.length > 0) {
          blInvoiceParam.blList = issBlData
          if (blInvoiceParam.blList.length === 0) {
            this.openAlert(this.$t('msg.DOIssue.003'))
            return
          }
          const invoiceList = await this.invoicePublish(blInvoiceParam)
          if (invoiceList.length === 0) {
            this.openAlert(this.$t('msg.DOIssue.003'))
            return
          }

          invoiceList.forEach(vo => {
            tmpData.push({
              invIssNo: vo.invIssNo,
              to: '',
              remark: '',
              filter: 'BL',
              blNo: vo.blNo,
              esign: ''
            })
          })

          componentName = 'BlInvoiceFreightPop'
          invoiceParam.items = tmpData
        } else {
          if (tmpData.length === 0) {
            this.openAlert(this.$t('msg.DOIssue.003'))
            return
          }
          componentName = 'BlInvoiceFreightPop'
          invoiceParam.items = tmpData
        }
      }

      this.popupParams = invoiceParam
      this.commonOpenPopup(componentName)
    },
    async invoicePublish (data) {
      let rtn = []
      try {
        await calcIssue.invoicePublish(data)
          .then(response => {
            if (response.data) {
              rtn = response.data
            }
          })
      } catch (e) {
        console.log(e)
      } finally {
        console.log('!종료')
      }
      return rtn
    },
    async openCombineInvoice (arrBlNo, eiCatCd) {
      const items = []
      arrBlNo.forEach(blNo => {
        items.push({ blNo: blNo, remark: '' })
      })
      this.combineInvoicePopCall(items, eiCatCd)
    },
    fnCombineInvoicePop (e, bkgNo, blNo, eiCatCd) {
      e.preventDefault()
      const items = []
      items.push({ blNo: blNo, remark: '' })
      this.combineInvoicePopCall(items, eiCatCd)
      this.$emit('popupOpen', 'fnCombineInvoicePop')
    },
    fnDemInvoiceMultiPop (e, bkgNo, blNo, eiCatCd, demDetCd) {
      const params = []
      params.push('blNo=' + blNo)
      params.push('dtKnd=BL')
      params.push('eiCatCd=' + eiCatCd)
      FreeTimeApi.getFreeTime('?' + params.join('&')).then(response => {
        if (eiCatCd === 'I' && response.data.asDemDetCatCd === '14') {
          demDetCd = '14'
        }

        const items = []
        items.push({ blNo: blNo, bkgNo: bkgNo })
        this.demInvoiceMultiPopCall(items, eiCatCd, demDetCd)
      })
    },
    async demInvoiceMultiPopCall (items, eiCatCd, demDetCd) {
      //demDetCatCd : 수입(01) , 수출(02)
      const blNo = items[0].blNo
      const paramData = '?dtKnd=BL&blNo=' + blNo + '&eiCatCd=' + eiCatCd

      await FreeTimeApi.getFreeTime(paramData).then(response => {
        this.freeTimeData = response.data
      })
      this.calculOpenPopup(blNo, eiCatCd, demDetCd)
    },
    async calculOpenPopup (blNo, eiCatCd, demDetCd) {
      const calExts = []
      let cnt = 0

      const items = this.freeTimeData.demdetail4list.map(obj => {
        const cntrNo = obj.cntrNo
        const extsDt = obj.extsDt
        let ovrDds = obj.ovrDds
        let totAmt = obj.totAmt
        let vatAmt = obj.vatAmt
        const subParam = '?blNo=' + blNo + '&cntrNo=' + cntrNo + '&demDetCatCd=' + demDetCd + '&eiCatCd=' + eiCatCd + '&extsDt=' + this.freeTimeData.toDay
        cnt++

        return FreeTimeApi.getFreeTimeExtdt(subParam).then(res => {
          const resMap = res.data.rstMap
          ovrDds = resMap.overDds
          totAmt = resMap.totAmt
          //TODO 국가별로 다름 설정 필요
          vatAmt = (parseInt(resMap.totAmt) + parseInt(resMap.totAmt) / 10)
          calExts.push(cntrNo + '_' + extsDt + '_' + ovrDds + '_' + totAmt + '_' + vatAmt)
        }).catch(e => {
          console.log(e)
        })
      })

      await Promise.all(items)

      if (cnt === 0) {
        this.openAlert(this.$t('msg.ONEX020P120.043'))
        return false
      }

      this.popupParams = {
        blNo: blNo,
        demDetCatCd: demDetCd,
        eiCatCd: eiCatCd,
        calExts: calExts.join(','),
        invIssNo: ''
      }
      this.commonOpenPopup('ProformaInvoicePop')
    },
    async combineInvoicePopCall (items, eiCatCd) {
      await this.recalculateFreightBillInfo(items)

      const params = {
        eiCatCd: eiCatCd,
        items: items
      }
      this.popupParams = params
      this.commonOpenPopup('CombineInvoicePop')
    },
    clearPopupParams () {
      this.freightBillInfo = {}
      this.detail = {}
      this.popupParams = {}
    },
    async commonOpenPopup (compNm, params) {
      console.log('----->', compNm)
      console.log('----->', params)

      if (params !== undefined) {
        this.popupParams = params
      }
      this.isShowPop = true
      this.customComponent = compNm
      // this.$ekmtcCommon.layerOpen('.unsolved_export_popup')
      // this.$ekmtcCommon.layerOpen(`#${this.compId}_unsolved_export_popup > .popup_dim`)

      switch (compNm) {
        case 'FareRegisReqPop':
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010P060.002'), useConfirmBtn: true })) {
            let regData = {}
            regData.blNo = this.popupParams.blNo
            regData.invRno = this.popupParams.invRno
            regData.index = this.popupParams.index
            regData.rtnCode = this.popupParams.rtnCode

            fare.insertInvoiceReq(regData).then(res => {
              if (res.headers.respcode === 'C0000') {
                this.$ekmtcCommon.alertDefault(this.popupParams.blNo + this.$t('msg.ONEX010P060.003')) //에 대한 운임요청이 접수되었습니다.
                this.$emit('close')
              } else {
                this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX010P060.004')) //운임요청에 실패하였습니다. 담당자에게 문의바랍니다.
              }
            })
          }
          break
        default:
          this.$ekmtcCommon.layerOpen(`#${this.compId}_unsolved_export_popup > .popup_dim`)
          break
      }
    },
    commonClosePopup () {
      console.log('popup close ------>')
      this.isShowPop = false
      this.customComponent = null
      // this.$ekmtcCommon.layerClose('.unsolved_export_popup')
      this.$ekmtcCommon.layerClose(`#${this.compId}_unsolved_export_popup > .popup_dim`)
      if (this.$store.state.settle.isSettleSearchData) {
        this.$emit('callback')
      }
    },
    // //BL발행 호출
    // async openBlPaymentPop (arrBlNo, eiCatCd) {
    //   //해당 BL들에 대한 status 셋팅 후 status별 벨리데이션
    //   const result = await blPayment.findBlpaymentStatus(arrBlNo)
    //   const data = result.data

    //   console.log('openBlPaymentPop data >>> ', data)

    //   let toOrderYn = 'N'
    //   for (let i = 0; i < data.length; i++) {
    //     let status = data[i].blStatus
    //     if (status === '04' || status === '06' || status === '07') {
    //         this.openAlert(this.$t('art.CSBL220.008')) /* 발행중이므로 다시 발행할수 없습니다. 요청취소후 다시 요청바랍니다. */
    //         return
    //     } else if (status === '02' || status === '03') {
    //         this.openAlert(this.$t('art.CSBL220.009')) /* 이미 발행 되었습니다. */
    //         return
    //     } else if (status === '01' || status === '11') { // O B/L 재발행팝업
    //         // 작성한 내용은 저장되지 않습니다. 팝업을 닫으시겠습니까?
    //         if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.024'), useConfirmBtn: true })) { //'이미 발행되었습니다. 재발행요청 하시겠습니까?'
    //           const params = {
    //             kind: 'BL',
    //             blNo: data[0].blNo,
    //             reqCatCd: '08',
    //             oblRe: true
    //           }
    //           this.commonOpenPopup('OriginalBLReIssuePop', params)
    //         }
    //         return
    //     } else if (status === '14' || status === '16' || status === '17') {
    //         this.openAlert(this.$t('art.CSBL220.010')) /* 이미 승인 되었습니다. */
    //         return
    //     }
    //     if (this.auth !== undefined) {
    //       if (this.auth.serviceLang === 'KOR') {
    //         if (data[i].noProcCaYn === 'Y') {
    //           this.openAlert(this.$t('art.CSBL220.014')) /* 아직 처리되지 않은 CA가 있습니다. 처리 후 다시 요청하시기 바랍니다.(CA 문의처 : docca@ekmtc.com 0517977400 (내선 2)) */
    //           return
    //         }
    //       }
    //     }
    //     //toOrderYn이 하나라도 있는 경우 팝업상세에서 제어하기 위한 벨리데이션 값
    //     if (data[i].toOrderYn === 'Y') {
    //       toOrderYn = 'Y'
    //     }

    //     const loiReqCatCd = data[i].loiReqCatCd
    //     if (this.$ekmtcCommon.isNotEmpty(loiReqCatCd)) {
    //       let message = ''
    //       if (loiReqCatCd.indexOf('01') > -1) {
    //         message = this.$t('msg.ADD20220221.000')
    //       } else if (loiReqCatCd.indexOf('01') > -1) {
    //         message = this.$t('msg.ADD20220221.001')
    //       } else if (loiReqCatCd.indexOf('03') > -1) {
    //         message = this.$t('msg.ADD20220221.002')
    //       } else if (loiReqCatCd.indexOf('04') > -1) {
    //         message = (data[i].loiEtcRmk || '') + this.$t('msg.ADD20220221.003')
    //       }

    //       message += '<br>' + data[i].loiTelNo

    //       this.$ekmtcCommon.alertDefault(message)
    //       returnf
    //     }
    //   }
    //   this.blDoPaymentPop(arrBlNo, eiCatCd, toOrderYn)
    // },
    //BL발행 호출
    async openSrdPop  (arrBlNo, eiCatCd, arrIsFwdrYn, issStstus) {
      //let arrSrdBlNo = []
      try {
      const result = await blPayment.findBlpaymentStatus(arrBlNo)
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let status = data[i].blStatus
        if (status === '04' || status === '06' || status === '07') {
            this.openAlert(this.$t('B/L 발행 중이므로, 요청 불가합니다.')) /* 발행중이므로 다시 발행할수 없습니다. 요청취소후 다시 요청바랍니다. */
            return
        } else if (status === '89') {
          this.openAlert(this.$t('art.CSBL220.023')) /* 해당 건에 대하여 이미 처리 중에 있습니다. */
          return
        } else if (status === '02' || status === '03' || status === '01') {
            this.openAlert(this.$t('B/L 발행이 완료되어, 요청 불가합니다.')) /* 이미 발행 되었습니다. */
            return
        }
      }
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('서렌더피 요청하시겠습니까?'), useConfirmBtn: true })) {
        await blPayment.findSrdFeeStatus(arrBlNo)
          .then(result => {
            this.fnSrdMsg(result)
          })
      }
      } catch (e) {
        console.log(e)
      } finally {
        //
      }
    },
    async fnSrdMsg (result) {
      this.arrSrdBlNo = []
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].srdAddYn === 'Y') {
          await this.$ekmtcCommon.asyncAlertMessage({
            message: result.data[i].blNo + ' : ' + this.$t('서렌더피 이미 추가 되었습니다.')
          })
               // this.openAlert(result.data[i].blNo + ' : ' + this.$t('서렌더피 이미 추가 되었습니다.'))
        } else if (result.data[i].cnWavYn === 'Y') {
          await this.$ekmtcCommon.asyncAlertMessage({
            message: result.data[i].blNo + ' : ' + this.$t('서렌더피 면제 지역 입니다')
           })//this.openAlert(result.data[i].blNo + ' : ' + this.$t('서렌더피 면제 지역 입니다.'))
           this.arrSrdBlNo.push(result.data[i].blNo)
        } else if (result.data[i].srdWavCstYn === 'Y') {
          await this.$ekmtcCommon.asyncAlertMessage({
            message: result.data[i].blNo + ' : ' + this.$t('서렌더피 면제 업체입니다.')
          })
          this.arrSrdBlNo.push(result.data[i].blNo)
          //this.openAlert(result.data[i].blNo + ' : ' + this.$t('해당 업체는 서렌더피 WAIVE 입니다.'))
        } else if (result.data[i].frtYn === 'N') {
          await this.$ekmtcCommon.asyncAlertMessage({
            message: result.data[i].blNo + ' : ' + this.$t('운임 인보이스가 존재하지 않습니다.\n담당 영업사원과 확인 후 다시 신청 바랍니다.')
           })
                //this.openAlert(result.data[i].blNo + ' : ' + this.$t('서렌더피 면제 지역 입니다.'))
        } else if ((result.data[i].srdWavYn === 'Y' && result.data[i].srdWavCstYn !== 'Y') || result.data[i].srdWavAddYn === 'Y') {
          await this.$ekmtcCommon.asyncAlertMessage({
            message: result.data[i].blNo + ' : ' + this.$t('서렌더피 WAIVE 입니다.')
          })
          this.arrSrdBlNo.push(result.data[i].blNo)
       } else {
          await this.$ekmtcCommon.asyncAlertMessage({
            message: result.data[i].blNo + ' : ' + this.$t('서렌더피 KRW 40,000원이 추가됩니다.')
           })
           this.arrSrdBlNo.push(result.data[i].blNo)
        }
      }
      if (this.$ekmtcCommon.isNotEmpty(this.arrSrdBlNo) && this.arrSrdBlNo.length > 0) {
        await blPayment.createSrdFee(this.arrSrdBlNo).then(result => {
          this.$emit('callback')
        })
      }
    },
    async openBlPaymentPop (arrBlNo, eiCatCd, arrIsFwdrYn, issStstus) {
      //해당 BL들에 대한 status 셋팅 후 status별 벨리데이션
      const result = await blPayment.findBlpaymentStatus(arrBlNo)
      const data = result.data

      console.log('openBlPaymentPop data >>> ', arrBlNo)
      let toOrderYn = 'N'
      let bankYn = 'N'
      for (let i = 0; i < data.length; i++) {
        let status = data[i].blStatus
        if (status === '04' || status === '06' || status === '07') {
            this.openAlert(this.$t('art.CSBL220.008')) /* 발행중이므로 다시 발행할수 없습니다. 요청취소후 다시 요청바랍니다. */
            return
        } else if (status === '89') {
          this.openAlert(this.$t('art.CSBL220.023')) /* 해당 건에 대하여 이미 처리 중에 있습니다. */
          return
        } else if (status === '02' || status === '03') {
            this.openAlert(this.$t('art.CSBL220.022')) /* 이미 발행 되었습니다. */
            return
        } else if (status === '01' || status === '11') { // O B/L 재발행팝업
          if (issStstus === 'SUR_TEL') {
            continue
          } else {
            const params = {
             searchList: { blNo: data[i].blNo, bkgNo: data[i].bkgNo, userId: this.auth.userId }
            }
            let isCheck = true
            await exportSvc.getOnGoingRequestBtn(params).then(res => {
              const authInfo = res.data
              if (authInfo.report04 !== 'Y') {
                isCheck = false
                this.openAlert(this.$t('art.CMATK232')) // 권한이 없습니다.
              }
            })
            if (!isCheck) {
              return
            }
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.024'), useConfirmBtn: true })) { //'이미 발행되었습니다. 재발행요청 하시겠습니까?'
              const params = {
                kind: 'BL',
                blNo: data[0].blNo,
                reqCatCd: '08',
                oblRe: true
              }
              this.commonOpenPopup('OriginalBLReIssuePop', params)
            }
            return
          }
        } else if (status === '14' || status === '16' || status === '17') {
            this.openAlert(this.$t('art.CSBL220.010')) /* 이미 승인 되었습니다. */
            return
        }
        if (this.auth !== undefined) {
          if (this.auth.serviceLang === 'KOR') {
            if (data[i].noProcCaYn === 'Y') {
              this.openAlert(this.$t('art.CSBL220.014')) /* 아직 처리되지 않은 CA가 있습니다. 처리 후 다시 요청하시기 바랍니다.(CA 문의처 : docca@ekmtc.com 0517977400 (내선 2)) */
              return
            }
          }
        }
        //toOrderYn이 하나라도 있는 경우 팝업상세에서 제어하기 위한 벨리데이션 값
        if (data[i].toOrderYn === 'Y') {
          toOrderYn = 'Y'
        }
        //bankYn 이 하나라도 있는 경우 팝업상세에서 제어하기 위한 벨리데이션 값
        //jp 일 경우
        if (data[i].bankYn === 'Y') {
          bankYn = 'Y'
        }

        // 중국 수출일 경우 fwdrCstNo가 로그인 한 cstNo와 다를 경우 BL발행 불가
        if (typeof arrIsFwdrYn !== 'undefined' || arrIsFwdrYn != null) {
          if (this.memberDetail.userCtrCd === 'CN') {
            for (let vo of arrIsFwdrYn) {
              // if (this.memberDetail.cstCd !== vo.fwdrCstNo) {
              if (vo.isFwdrYn !== 'Y') {
                this.openAlert(this.$t('msg.ADD20220219.054')) // '발행요청 권한이 없습니다.'
                return
              }
            }
          }
        }

        const loiReqCatCd = data[i].loiReqCatCd
        const loiEtcRmk = data[i].loiEtcRmk

        if (this.$ekmtcCommon.isNotEmpty(loiReqCatCd)) {
          let message = ''
          if (loiReqCatCd.indexOf('01') > -1) {
            message = this.$t('msg.ADD20220221.000') //"제 3국 SHPR LOI가 처리되지 않았습니다."
          } else if (loiReqCatCd.indexOf('02') > -1) {
            message = this.$t('msg.ADD20220221.001') // "중량초과각서가 처리되지 않았습니다."
          } else if (loiReqCatCd.indexOf('03') > -1) {
            message = this.$t('msg.ADD20220221.002') // "DOP 선적 LOI가 처리되지 않았습니다."
          } else if (loiReqCatCd.indexOf('04') > -1) {
              if (this.$ekmtcCommon.isNotEmpty(loiEtcRmk)) {
                message = loiEtcRmk
              } else {
                message = this.$t('tem.CMAT037') // 서류 담당자와 확인 부탁드립니다. (data[i].loiEtcRmk || '') + this.$t('msg.ADD20220221.003')
              }
          }

          if (this.$ekmtcCommon.isNotEmpty(data[i].loiTelNo)) {
            message += '<br>' + data[i].loiTelNo
          }

          this.$ekmtcCommon.alertDefault(message)
          return
        }
      }
      this.blDoPaymentPop(arrBlNo, eiCatCd, toOrderYn, '', issStstus, null, bankYn)
    },
    async openDORequestEtcPop (arrBlNo, eiCatCd, voArr, issStstus) {
      //해당 BL들에 대한 status 셋팅 후 status별 벨리데이션
      // const result = await blPayment.findBlpaymentStatus(arrBlNo)
      const data = voArr

      console.log('openBlPaymentPop data >>> ', data)

      if (voArr.length > 0) {
        this.doJpData = voArr
      }

      for (let i = 0; i < data.length; i++) {
        let doApvStsCd = data[i].doApvStsCd
        let doStsCd = data[i].doStsCd

        if (doStsCd === '1') {
          this.openAlert(this.$t('art.CSBL220.022')) /* 이미 발행 되었습니다. */
          return
        } else {
          if (doApvStsCd === '02') { // 요청중
            this.openAlert(this.$t('msg.DOIssue.002'))
            return
          } else if (doApvStsCd === '03') {
            this.openAlert(this.$t('art.CSBL220.022')) /* 이미 발행 되었습니다. */
            return
          } else if (doApvStsCd === '07') {
            this.openAlert(this.$t('art.CSBL220.023')) /* 해당 건에 대하여 이미 처리 중에 있습니다. */
            return
          }
        }
      }

      this.blDoPaymentPop(arrBlNo, eiCatCd, '', '', issStstus)
    },
    //DO발행 호출
    async openDoPaymentPop (arrBlNo, eiCatCd, param) {
      // 다음 프로세스 변수 초기화
      this.nextArrBlNo = null
      this.nextEiCatCd = null
      this.nextParam = null
      this.nextData = null

      //해당 DO들에 대한 status 셋팅 후 status별 벨리데이션
      const result = await doPayment.findDoPaymentStatus(arrBlNo)
      console.log('doPayment.findDoPaymentStatus >>', arrBlNo)
      const data = result.data

      console.log('doPayment.findDoPaymentStatus data >>', data)

      if (data.length === 0) {
        this.openAlert(this.$t('msg.DOIssue.004')) // D/O 발행이 불가능합니다.
        return
      } else {
        if (arrBlNo.length !== data.length) {
          this.openAlert(this.$t('msg.DOIssue.005')) //D/O 발행이 불가능한 건을 포함하고 있습니다.
          return
        }
      }

      console.log('openDoPaymentPop >>>> ', param)

      // 위수임 기간 등의 조건 체크
      let acceptCnt = 0
      let rejectCnt = 0
      let extDtAlertMsg = ''
      data.map((item) => {
        let extCstNm = ''
        let year = ''
        let month = ''
        let day = ''
        if (item.extDt) {
          extCstNm = item.extDt.split('@@')[0]
          year = item.extDt.split('@@')[1].substring(0, 4) + '.'
          month = item.extDt.split('@@')[1].substring(4, 6) + '.'
          day = item.extDt.split('@@')[1].substring(6, 8)
        }

        let resultDate = year + month + day

        if (item.extDt) {
          if (item.extDt.split('@@')[1] > item.toDay) {
            // this.openAlert(extCstNm + this.$t('msg.DOIssue.006') + resultDate + this.$t('msg.DOIssue.007'))
            extDtAlertMsg += (extDtAlertMsg !== '' ? '\n' : '') + extCstNm + this.$t('msg.DOIssue.006') + resultDate + this.$t('msg.DOIssue.007')
          } else {
            // this.openAlert(extCstNm + this.$t('msg.DOIssue.006') + resultDate + this.$t('msg.DOIssue.026'))
            extDtAlertMsg += (extDtAlertMsg !== '' ? '\n' : '') + extCstNm + this.$t('msg.DOIssue.006') + resultDate + this.$t('msg.DOIssue.026')
          }
        }

        if (item.doStsCd === '4' || item.doStsCd === '6' || item.doStsCd === '7') {
          acceptCnt += 1
        } else {
          rejectCnt += 1
        }
      })

      if (extDtAlertMsg !== '') {
        this.nextArrBlNo = arrBlNo
        this.nextEiCatCd = eiCatCd
        this.nextParam = param
        this.nextData = data
        this.openAlert(extDtAlertMsg, this.openDoPaymentPopNextProcess)
      } else {
        //발행가능한 PDF출력 대상 BL
        let pdfPossibleArr = []
        data.map((item) => {
          if (item.doStsCd === '1' || item.doStsCd === '2') {
            pdfPossibleArr.push({
              blNo: item.blNo,
              doRno: item.doRno
            })
          }
        })

        let tempBlNoArr = []
        for (let i = 0; i < data.length; i++) {
          //1.발행여부 체크
          // if (data[i].doStsCd === '1' || data[i].doStsCd === '2') {
          //   if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.009'), useConfirmBtn: true })) {
          //     if (!this.beforePdfCheck(pdfPossibleArr, arrBlNo)) {
          //       return
          //     }
          //   }
          //   return
          // }

          //2.DEMDET 프리타임 체크
          let demDetResult = await doPayment.demDetCheck(arrBlNo)

          if (demDetResult.data) {
            //OFC_YN
            let ofcYn = await doPayment.freetimeCheck(demDetResult.data)

            let message = ''
            if (ofcYn.data === 'Y') {
              message = this.$t('msg.DOIssue.010')
            } else {
              message = this.$t('msg.DOIssue.011')
            }

            let obj = {
              alertType: 'simple',
              showXclose: true,
              customCloseBtnClass: 'button blue lg',
              customCloseBtnText: this.$t('msg.DOIssue.023'),
              customConfirmBtnText: ofcYn.data === 'Y' ? this.$t('msg.DOIssue.012') : this.$t('msg.DOIssue.013'),
              customConfirmBtnClass: 'button blue lg',
              useConfirmBtn: true,
              message: message,
              type: 'info',
              close: true
            }

            //OFC 확인 및 DEM/DET 확인 클릭시 프리타임 페이지 이동
            if (await this.$ekmtcCommon.asyncAlertMessage(obj)) {
              const query = {
                dtKnd: 'BL',
                eiCatCd: 'I',
                num: demDetResult.data
              }
              let params = {
                  eiCatCd: 'I',
                  dtKnd: 'BL',
                  num: demDetResult.data,
                  blNo: '',
                  cntrNo: ''
                }
                this.$store.commit('settle/updateFreetimeParams', params)
                this.$router.push({ name: 'FreeTimeRequest', query: query }).catch(() => {})
              return
            }
          }

          if (data[i].doStsCd === '4' || data[i].doStsCd === '7') { //4 :
            // O.B/L이 접수되지 않았을 경우 경고없이 진행하도록 수정
            // if (data[i].doStsCd === '7') {
            //   this.openAlert('[' + data[i].blNo + '] ' + this.$t('msg.DOIssue.014'))
            // }
            //체크 한 개일 때
            if (arrBlNo.length === 1) {
              this.blDoPaymentPop(arrBlNo, eiCatCd, '', '', '', param.collectBl)
            } else {
              tempBlNoArr.push(data[i].blNo)
            }
          } else if (data[i].doStsCd === '3') { //요청중
            this.openAlert(this.$t('msg.ONIM010T010.011'))
            return
          } else if (data[i].doStsCd === '5') {
            this.openAlert(this.$t('msg.DOIssue.016')) //O.B/L이 접수되지 않았거나 WAYBILL처리되지 않았습니다.
            return
          } else if (data[i].doStsCd === '6') {
            console.log('data[i].doStsCd tempBlNoArr 6')
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.017') + data[i].rejectCont + '\n\n' + this.$t('msg.DOIssue.018'), useConfirmBtn: true })) {
              //체크 한 개일 때
              if (arrBlNo.length === 1) {
                this.blDoPaymentPop(arrBlNo, eiCatCd, '', '', '', param.collectBl)
                return
              } else {
                tempBlNoArr.push(data[i].blNo)
              }
            }
          } else if (data[i].doStsCd === '8') { // 거절
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.019') + '\n\n' + this.$t('msg.DOIssue.018'), useConfirmBtn: true })) {
              //체크 한 개일 때
              if (data.length === 1) {
                this.blDoPaymentPop(arrBlNo, eiCatCd, '', '', '', param.collectBl)
                return
              } else {
                tempBlNoArr.push(data[i].blNo)
              }
            }
          } else {
            this.openAlert(this.$t('msg.DOIssue.020'))
            return
          }
        }

        //체크 2개 이상일 때
        if (tempBlNoArr && tempBlNoArr.length > 0) {
          const tempCollectBl = []

          param.collectBl.forEach(item => {
            // const vo = rowData[0]
            const isDuplicat = tempBlNoArr.filter(v => v === item.blNo)
            if (isDuplicat.length > 0 && item.frtPncCd === 'C') {
              tempCollectBl.push(item)
            }
          })

          this.blDoPaymentPop(tempBlNoArr, eiCatCd, '', '', '', param.collectBl)
        }
      }
    },
    //DO발행 호출
    async openDoPaymentPopNextProcess () {
      //발행가능한 PDF출력 대상 BL
      let pdfPossibleArr = []
      this.nextData.map((item) => {
        if (item.doStsCd === '1' || item.doStsCd === '2') {
          pdfPossibleArr.push({
            blNo: item.blNo,
            doRno: item.doRno
          })
        }
      })

      let tempBlNoArr = []
      for (let i = 0; i < this.nextData.length; i++) {
        //1.발행여부 체크
        // if (this.nextData[i].doStsCd === '1' || this.nextData[i].doStsCd === '2') {
        //   if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.009'), useConfirmBtn: true })) {
        //     if (!this.beforePdfCheck(pdfPossibleArr, this.nextArrBlNo)) {
        //       return
        //     }
        //   }
        //   return
        // }

        //2.DEMDET 프리타임 체크
        let demDetResult = await doPayment.demDetCheck(this.nextArrBlNo)

        if (demDetResult.data) {
          //OFC_YN
          let ofcYn = await doPayment.freetimeCheck(demDetResult.data)

          let message = ''
          if (ofcYn.data === 'Y') {
            message = this.$t('msg.DOIssue.010') //해당 화물의 무료장치기간이 초과된 상태입니다.<br><br>Over Free Time Charge의 상세 정보를 원하시면 `OFC 확인` 키를, <br><br>무시하고 바로 D/O 발행 요청하시려면 `바로가기` 키를 클릭해 주시기 바랍니다.
          } else {
            message = this.$t('msg.DOIssue.011') //해당 화물의 DEMURRAGE FREE TIME 이 초과된 상태입니다.<br><br>DEMURRAGE 상세 정보를 원하시면 `DEM/DET 확인` 키를, <br><br>무시하고 바로 D/O 발행 요청하시려면 `바로가기` 키를 클릭해 주시기 바랍니다.
          }

          let obj = {
            alertType: 'simple',
            showXclose: true,
            customCloseBtnClass: 'button blue lg',
            customCloseBtnText: this.$t('msg.DOIssue.023'),
            customConfirmBtnText: ofcYn.data === 'Y' ? this.$t('msg.DOIssue.012') : this.$t('msg.DOIssue.013'),
            customConfirmBtnClass: 'button blue lg',
            useConfirmBtn: true,
            message: message,
            type: 'info',
            close: true
          }

          //OFC 확인 및 DEM/DET 확인 클릭시 프리타임 페이지 이동
          if (await this.$ekmtcCommon.asyncAlertMessage(obj)) {
            const query = {
              dtKnd: 'BL',
              eiCatCd: 'I',
              num: demDetResult.data
            }
             let params = {
                eiCatCd: 'I',
                dtKnd: 'BL',
                num: demDetResult.data,
                blNo: '',
                cntrNo: ''
              }
              this.$store.commit('settle/updateFreetimeParams', params)
              this.$router.push({ name: 'FreeTimeRequest', query: query }).catch(() => {})
            return
          }
        }

        if (this.nextData[i].doStsCd === '4' || this.nextData[i].doStsCd === '7') { //4 :
          if (this.nextData[i].doStsCd === '7') {
            this.openAlert('[' + this.nextData[i].blNo + '] ' + this.$t('msg.DOIssue.014')) // O.B/L이 접수되지 않았습니다.
          }
          //체크 한 개일 때
          if (this.nextArrBlNo.length === 1) {
            this.blDoPaymentPop(this.nextArrBlNo, this.nextEiCatCd, '', '', '', this.nextParam.collectBl)
          } else {
              tempBlNoArr.push(this.nextData[i].blNo)
          }
        } else if (this.nextData[i].doStsCd === '3') { //요청중
          this.openAlert(this.$t('msg.ONIM010T010.011'))
          return
        } else if (this.nextData[i].doStsCd === '5') {
          this.openAlert(this.$t('msg.DOIssue.016')) //O.B/L이 접수되지 않았거나 WAYBILL처리되지 않았습니다.
          return
        } else if (this.nextData[i].doStsCd === '6') {
          console.log('this.nextData[i].doStsCd tempBlNoArr 6')
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.017') + this.nextData[i].rejectCont + '\n\n' + this.$t('msg.DOIssue.018'), useConfirmBtn: true })) {
            //체크 한 개일 때
            if (this.nextArrBlNo.length === 1) {
              this.blDoPaymentPop(this.nextArrBlNo, this.nextEiCatCd, '', '', '', this.nextParam.collectBl)
              return
            } else {
              tempBlNoArr.push(this.nextData[i].blNo)
            }
          }
        } else if (this.nextData[i].doStsCd === '8') { // 거절
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.019') + '\n\n' + this.$t('msg.DOIssue.018'), useConfirmBtn: true })) {
            //체크 한 개일 때
            if (this.nextData.length === 1) {
              this.blDoPaymentPop(this.nextArrBlNo, this.nextEiCatCd, '', '', '', this.nextParam.collectBl)
              return
            } else {
              tempBlNoArr.push(this.nextData[i].blNo)
            }
          }
        } else {
          this.openAlert(this.$t('msg.DOIssue.020')) // 발행이 불가합니다. 사유는 D/O상태 항목 참조 바랍니다.
          return
        }
      }

      //체크 2개 이상일 때
      if (tempBlNoArr && tempBlNoArr.length > 0) {
        const tempCollectBl = []

        this.nextParam.collectBl.forEach(item => {
          // const vo = rowData[0]
          const isDuplicat = tempBlNoArr.filter(v => v === item.blNo)
          if (isDuplicat.length > 0 && item.frtPncCd === 'C') {
            tempCollectBl.push(item)
          }
        })
        this.blDoPaymentPop(tempBlNoArr, this.nextEiCatCd, '', '', '', this.nextParam.collectBl)
      }
    },
    //D/O발행 위수임 여부 체크
    async checkDelegate (arrBlNo) {
      let data = await doPayment.selectDelegateTerm(arrBlNo)
      let checkFlag = data.data.checkFlag

      let delegateFlag = true
      if ((checkFlag === 'D' || checkFlag === 'B') && arrBlNo.length > 1) { //REJECT : 위임기간 체크 넘기고 발행팝업X
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.021') }) // D/O 발행 권한 위임 관련된 메시지가 있습니다. 개별 D/O 발행으로 진행 바랍니다.
        delegateFlag = 'REJECT'
        return false
      }
      if (checkFlag === 'A' || checkFlag === 'C' || checkFlag === 'X' || checkFlag === 'D' || checkFlag === 'B') { //SUCCESS : 위임기간 체크 넘기고 발행팝업
        delegateFlag = 'SUCCESS'
        return true
      }
    },
    //DO발행 호출
    async DeliveryOrderPop (arrBlNo) {
      this.clearPopupParams()
      const items = []
      arrBlNo.forEach(blNo => {
        const eachBlNo = blNo
        const initObj = {
          blNo: eachBlNo,
          doRno: eachBlNo
        }
        items.push(initObj)
      })
      this.popupParams = {
        items: items
      }
      this.commonOpenPopup('DeliveryOrderPop')
    },
    // //B/L, D/O 팝업 최종 호출
    // async blDoPaymentPop (arrBlNo, eiCatCd, etc, arrFrtPncCd, issStstus) {
    //   //인보이스 정보
    //   let invoiceList = []
    //   await calcIssue.getBlInvoiceData({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
    //     .then(async response => {
    //       invoiceList = response.data
    //     })

    //   //운임 정보
    //   let freightMap
    //   await calcIssue.getBlFreight({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
    //     .then(async response => {
    //       console.log(response)
    //       freightMap = response.data
    //     })

    //   if (freightMap === null || freightMap === undefined) {
    //     freightMap = {}
    //   }

    //   let blList = []

    //   arrBlNo.forEach(blNo => {
    //     const list = freightMap[blNo]

    //     let invoiceInfo = invoiceList.filter((item) => {
    //       return item.blNo === blNo
    //     })[0]

    //     if (list) {
    //       list.forEach(bl => {
    //         let krw = 0
    //         if (bl.occrCurCd !== undefined) {
    //           if (bl.occrCurCd === 'KRW') {
    //             krw = parseInt(bl.occrAmt)
    //           } else if (bl.occrCurCd === 'USD') {
    //             //
    //           } else {
    //             krw = parseInt(bl.occrAmt) * parseFloat(bl.exRate)
    //             bl.occrCurCd = 'KRW'
    //           }
    //         } else {
    //           krw = bl.krwCurCd === 'KRW' ? parseInt(bl.krwAmt) : 0
    //         }

    //         blList.push({
    //           blNo: blNo,
    //           invIssNo: invoiceInfo.invIssNo,
    //           invRno: invoiceInfo.invRno,
    //           krw: krw,
    //           usd: bl.usdCurCd === 'USD' ? parseInt(bl.usdAmt) : 0,
    //           exRate: bl.usdCurCd === 'USD' ? parseFloat(bl.usdExRate) : 1,
    //           currency: bl.usdCurCd === 'KRW' ? 'KRW' : 'KRW+USD',
    //           obrdDt: invoiceInfo.obrdDt,
    //           eiCatCd: eiCatCd, // O: 수출, I: 수입
    //           cntrList: []
    //         })
    //       })
    //     } else {
    //       blList.push({
    //         blNo: blNo,
    //         invIssNo: invoiceInfo.invIssNo,
    //         invRno: invoiceInfo.invRno,
    //         krw: 0,
    //         usd: 0,
    //         exRate: 0,
    //         currency: 'KRW+USD',
    //         obrdDt: invoiceInfo.obrdDt,
    //         eiCatCd: eiCatCd, // O: 수출, I: 수입
    //         cntrList: []
    //       })
    //     }
    //   })

    //   //미정산 금액 여부 체크
    //   let totalPaid = 0
    //   blList.map(item => {
    //     totalPaid += (Number(item.krw) + Number(item.usd))
    //   })
    //   let paidFlag = true
    //   if (totalPaid === 0) {
    //     paidFlag = false
    //   }

    //   //props setting
    //   const userDmdInfo = await this.getUserDmd()
    //   blList = this.removeDuplicatedBlList(blList)
    //   this.freightBillInfo = {
    //     dpId: '',
    //     dmdCatCd: eiCatCd === 'O' ? userDmdInfo.etDmdCatCd : userDmdInfo.imDmdCatCd, // 화주 구분값(etDmdCatCd: 수출, imDmdCatCd: 수입)
    //     btnType: eiCatCd === 'O' ? 'BL' : 'DO', //BL: BL 발행, DO: DO발행
    //     hasNotPaid: paidFlag, //미정산 금액 존재여부
    //     hasNotTaxInvoice: false, //세금계산서 미발행 건 존재 여부
    //     blList: blList,
    //     arrFrtPncCd: arrFrtPncCd === 'C' ? arrFrtPncCd : '',
    //     toOrderYn: eiCatCd === 'O' ? etc : '',
    //     issStstus: issStstus
    //   }

    //   //발행팝업 호출
    //   const ctrCd = (this.memberDetail.userCtrCd === 'KR' ? '' : 'Etc')
    //   //발행팝업 호출
    //   // this.commonOpenPopup((eiCatCd === 'O' ? 'BL' : 'DO') + 'Payment' + ctrCd + 'Pop')
    //   this.commonOpenPopup((eiCatCd === 'O' ? 'BL' : 'DO') + 'Payment' + (eiCatCd === 'O' ? ctrCd : '') + 'Pop')
    //   //this.commonOpenPopup((eiCatCd === 'O' ? 'BL' : 'DO') + 'PaymentPop')
    // },
    //B/L, D/O 팝업 최종 호출
    async blDoPaymentPop (arrBlNo, eiCatCd, etc, arrFrtPncCd, issStstus, collectBlList, bankYn) {
      //인보이스 정보
      let invoiceList = []
      await calcIssue.getBlInvoiceData({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
        .then(async response => {
          invoiceList = response.data
        })

      //운임 정보
      let freightMap
      await calcIssue.getBlFreight({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
        .then(async response => {
          console.log(response)
          freightMap = response.data
        })

      if (freightMap === null || freightMap === undefined) {
        freightMap = {}
      }

      //운임 정보(KRW)
      let freightKrwMap
      await calcIssue.getBlFreightKrw({ arrBlNo: arrBlNo, eiCatCd: eiCatCd })
        .then(async response => {
          console.log(response)
          freightKrwMap = response.data
        })

      if (freightMap === null || freightMap === undefined) {
        freightMap = {}
      }

      let blList = []

      arrBlNo.forEach(blNo => {
        const list = freightMap[blNo]
        const listKrw = freightKrwMap[blNo]
        let krwVal = ''

        let invoiceInfo = invoiceList.filter((item) => {
          return item.blNo === blNo
        })[0]

        if (list) {
          list.forEach(bl => {
            let krw = 0
            if (bl.occrCurCd !== undefined) {
              if (bl.occrCurCd === 'KRW') {
                krw = parseInt(bl.occrAmt)
              } else if (bl.occrCurCd === 'USD') {
                //
              } else {
                krw = parseInt(bl.occrAmt) * parseFloat(bl.exRate)
                bl.occrCurCd = 'KRW'
              }
            } else {
              krw = bl.krwCurCd === 'KRW' ? parseFloat(bl.krwAmt) : 0
            }

            blList.push({
              blNo: blNo,
              invIssNo: invoiceInfo.invIssNo,
              invRno: invoiceInfo.invRno,
              krw: krw,
              usd: bl.usdCurCd === 'USD' ? parseFloat(bl.usdAmt) : 0,
              exRate: bl.usdCurCd === 'USD' ? parseFloat(bl.usdExRate) : 1,
              currency: bl.usdCurCd === 'KRW' ? 'KRW' : 'KRW+USD',
              obrdDt: invoiceInfo.obrdDt,
              eiCatCd: eiCatCd, // O: 수출, I: 수입
              cntrList: [],
              billLocAmt: listKrw[0].billLocAmt || ''
            })
          })
        } else {
          blList.push({
            blNo: blNo,
            invIssNo: invoiceInfo.invIssNo,
            invRno: invoiceInfo.invRno,
            krw: 0,
            usd: 0,
            exRate: 0,
            currency: 'KRW+USD',
            obrdDt: invoiceInfo.obrdDt,
            eiCatCd: eiCatCd, // O: 수출, I: 수입
            cntrList: []
          })
        }
      })

      //미정산 금액 여부 체크
      let totalPaid = 0
      blList.map(item => {
        totalPaid += (Number(item.krw) + Number(item.usd))
      })
      let paidFlag = true
      if (totalPaid === 0) {
        paidFlag = false
      }

      //props setting
      const userDmdInfo = await this.getUserDmd()
      blList = this.removeDuplicatedBlList(blList)
      this.freightBillInfo = {
        dpId: '',
        dmdCatCd: eiCatCd === 'O' ? userDmdInfo.etDmdCatCd : userDmdInfo.imDmdCatCd, // 화주 구분값(etDmdCatCd: 수출, imDmdCatCd: 수입)
        btnType: eiCatCd === 'O' ? 'BL' : 'DO', //BL: BL 발행, DO: DO발행
        hasNotPaid: paidFlag, //미정산 금액 존재여부
        hasNotTaxInvoice: false, //세금계산서 미발행 건 존재 여부
        blList: blList,
        arrFrtPncCd: arrFrtPncCd === 'C' ? arrFrtPncCd : '',
        toOrderYn: eiCatCd === 'O' ? etc : '',
        issStstus: issStstus,
        collectBlList: collectBlList,
        bankYn: bankYn
      }

      //발행팝업 호출
      //const ctrCd = (this.memberDetail.userCtrCd === 'KR' ? '' : 'Etc')
      //발행팝업 호출
      //this.commonOpenPopup((eiCatCd === 'O' ? 'BL' : 'DO') + 'Payment' + ctrCd + 'Pop')
      //this.commonOpenPopup((eiCatCd === 'O' ? 'BL' : 'DO') + 'Payment' + (eiCatCd === 'O' ? ctrCd : '') + 'Pop')
      //this.commonOpenPopup((eiCatCd === 'O' ? 'BL' : 'DO') + 'PaymentPop')
      if (eiCatCd === 'O') {
        let arrBlErrList = await blPayment.findBlErrorCodeList(arrBlNo) //BL에러코드목록조회
        let isErrMsg = false //메시지존재여부
        let isErrPrint = true //에러팝업 출력여부(외국어 일때 특정코드 체크용)
        let message = '' //confirm에 출력할 본문내용
        let ctrCd = (this.memberDetail.userCtrCd === 'KR' ? this.memberDetail.userCtrCd : 'Eng')
        // 한국이 아닌경우 다음 에러 코드만 출력
        let etcDisplayErrCd = ['107', '207', '190', '290']

        // 한국이 아닌경우 특정 코드를 제외하고는 메시지창 Skip 한다
        if (ctrCd !== 'KR') {
          isErrPrint = false
          for (let blRow of arrBlErrList.data) {
            for (let errInfo of blRow.errorList) {
              if (etcDisplayErrCd.indexOf(errInfo.blErrCd) > -1) {
                isErrPrint = true
                break
              }
            }
          }
        }

        if (arrBlErrList && arrBlErrList.data && isErrPrint) {
          let isFirst = true
          message += '<div class="tal pr10" style="overflow: auto; max-height:280px;">'
          for (let blRow of arrBlErrList.data) {
            message += `<h4${(isFirst ? '' : ' class="mt15"')}>${blRow.blNo}</h4>`
            if (isFirst) isFirst = false

            if (blRow.errorList.length > 0) {
              isErrMsg = true
              message += '<ul class="bul_list_sm t2">'
              for (let errInfo of blRow.errorList) {
                // 국내가 아닌 경우 특정 에러코드만 출력
                if (ctrCd !== 'KR' && etcDisplayErrCd.indexOf(errInfo.blErrCd) < 0) {
                  continue
                }
                message += `<li><div class="pl-3">${errInfo.blErrMsg}</div></li>`
              }
              message += '<ul>'
            }
          }
          message += '</div>'
          message += '<div class="tal mt20"><h4>' + this.$t('art.CSBL220.012') + '<br/>' + this.$t('art.CSBL220.021') + '<h4></div>'
        }

        //BL발행인 경우 사유 메시지를 확인 하여 처리
        if (isErrMsg) {
          let obj = {
                alertType: 'simple',
                showXclose: true,
                customCloseBtnClass: 'button blue lg',
                //customCloseBtnText: this.$t('msg.DOIssue.023'),// 바로가기
                customConfirmBtnText: this.$t('msg.CMBA370.007'), // BL발행요청
                customConfirmBtnClass: 'button blue lg',
                useConfirmBtn: true,
                message: message,
                type: 'info',
                close: true
              }
          if (await this.$ekmtcCommon.asyncAlertMessage(obj)) {
            this.commonOpenPopup('BLPayment' + (this.memberDetail.userCtrCd === 'KR' ? '' : 'Etc') + 'Pop') //BL
          }
        } else {
          this.commonOpenPopup('BLPayment' + (this.memberDetail.userCtrCd === 'KR' ? '' : 'Etc') + 'Pop') //BL
        }
      } else {
        if (['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) >= 0) {
          this.freightBillInfo.podPortCd = this.doJpData[0].podPortCd
          this.freightBillInfo.doJpData = this.doJpData

          const blList = this.freightBillInfo.blList
          const doJpData = this.doJpData

          for (var bl of blList) {
            for (var doData of doJpData) {
              if (doData.blNo === bl.blNo) {
                bl.podPortCd = doData.podPortCd
                bl.hcneCstNo = doData.hcneCstNo
              }
            }
          }

          this.freightBillInfo.blList = blList

          this.commonOpenPopup('DORequestEtcPop') //DO Request
        } else {
          this.commonOpenPopup('DOPaymentPop') //DO
        }
      }
    },
    //PDF출력 전 체크
    async beforePdfCheck (pdfPossibleArr, arrBlNo) {
        let data = await transCommon.checkAuthDoCp({ blNoArr: arrBlNo, eiCatCd: 'I' })
        let result = data.data.result
        let blType = data.data.blType
        let strType = data.data.strType
        let remark = data.data.remark

        if (result === 'SUCCESS') {
          if (blType && !strType) {
            this.openAlert('B/L No: ' + blType + this.$t('msg.DOIssue.022'))
          }
          if (!blType && strType) {
            //D/O발행 PDF 출력
            this.popupParams = {
              items: [
                ...pdfPossibleArr
              ]
            }
            this.commonOpenPopup('DeliveryOrderPop')
            return true
          }
          if (blType && strType) {
            this.openAlert('B/L No: ' + blType + this.$t('msg.DOIssue.022'))
            //D/O발행 PDF 출력
            this.popupParams = {
              items: [
                ...pdfPossibleArr
              ]
            }
            this.commonOpenPopup('DeliveryOrderPop')
            return true
          }
        } else if (result === 'NO_DATA' || result === 'DO_NOT_ISSUED' || result === 'ALREADY_ISSUED') {
          this.openAlert(remark)
          return false
        } else if (result === 'NO_AUTH' || result === 'ERROR') {
          this.openAlert(remark)
          return false
        }
    },
    async recalculateFreightBillInfo (items) {
      let blArrStr = ''

      for (let i = 0; i < items.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += items[i].blNo
      }

      let req = {
        blNo: blArrStr
      }

      await CalcIssue.recalculateFreightBillInfo(req).then(response => {
        console.log('Success')
      }).catch(e => {
        console.log(e)
      })
    }
  }
}

</script>
