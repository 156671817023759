var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: `${_vm.compId}_unsolved_export_popup` } },
    [
      _c(
        "win-layer-pop",
        { staticClass: "unsolved_export_popup" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: {
                  "ok-hidden": _vm.isHidden,
                  "is-show-pop": _vm.isShowPop,
                  "freight-bill-info": _vm.freightBillInfo,
                  detail: _vm.detail,
                  "parent-info": _vm.popupParams,
                },
                on: { close: _vm.commonClosePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }