import Send from '../../trans.client.js'

export default {
  //B/L 발행 상세 정보 가져오기
  findBlPaymentDetail (formData) {
    return Send({
      url: '/trans/bl/payment?blNoArr=' + formData.blNoArr,
      method: 'get'
    })
  },
  //B/L발행 요청
  insertBlRequest (formData) {
    return Send({
      url: '/trans/bl/payment',
      method: 'post',
      data: formData
    })
  },
  //B/L 발행 상세 정보 가져오기
  findBlpaymentStatus (blNoArr) {
    return Send({
      url: '/trans/bl/payment/status?blNoArr=' + blNoArr,
      method: 'get'
    })
  },
  //다건 B/L의 에러 리스트 조회
  findBlErrorCodeList (blNoArr) {
    return Send({
      url: '/trans/bl/find-bl-error-list?blNoArr=' + blNoArr,
      method: 'get'
    })
  },
  //서렌더피 상세 정보 가져오기
  findSrdFeeStatus (blNoArr, reqBizCatCd) {
    return Send({
      url: '/trans/bl/srd-fee?blNoArr=' + blNoArr + '&issStsCd=' + reqBizCatCd,
      method: 'get'
    })
  },
    //서렌더피 상세 정보 가져오기
  createSrdFee (blNoArr) {
    return Send({
      url: '/trans/bl/create-srd-fee?blNoArr=' + blNoArr,
      method: 'get'
    })
  }
}
