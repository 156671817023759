import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //운송사 정보조회
  getTransComanyList (txtCompany, selectRow) {
    return Send({
      url: '/trans/bookings/trans-company',
      method: 'get',
      params: {
        selectRow: selectRow || '0',
        txtTransCompany: txtCompany
      }
    })
  },
  getPickUpList (formData) {
    return Send({
      url: '/trans/pickup',
      method: 'get',
      params: {
        selectRow: formData.selectRow || '0',
        cntrSz: formData.cntrSize,
        cntrTyp: formData.cntrType,
        polPortCd: formData.polPortCd,
        pickupDate: formData.pickupDate,
        cyCd: formData.cyCd
      }
    })
  },
  getBidgChk (cstBidgMgrNo) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/bidding-input',
      params: { cstBidgMgrNo: cstBidgMgrNo }
    })
  },
  getPickupOne (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/booking-pickup-one',
      params: {
        pcupCyCd: params.pcupCyCd,
        cntrSzCd: params.cntrSzCd,
        cntrTypCd: params.cntrTypCd,
        polPortCd: params.polPortCd
      }
    })
  },
  getChclAct (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/find-chcl-act',
      params: {
        polPortCd: params.polPortCd,
        etd: params.etd,
        imdgNo: params.imdgNo
      }
    })
  },
  getReeferYnCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/reefer-yn-check',
      params: {
        vslCd: params.vslCd,
        voyNo: params.voyNo,
        polCtrCd: params.polCtrCd
      }
    })
  },
  getCfsInfo (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/find-cfs-code',
      params: {
        polPortCd: params.polPortCd,
        podPortCd: params.podPortCd,
        podCtrCd: params.podCtrCd,
        vslCd: params.vslCd,
        voyNo: params.voyNo,
        polTrmlCd: params.polTrmlCd,
        arrCntrTypCd: params.arrCntrTypCd,
        conCode: params.conCode
      }
    })
  },
  getKmdVslCheck (hidCS008I) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/kmd-vsl-check',
      params: { hidCS008I: hidCS008I }
    })
  },
  getCasNoPortCheck (hidCS008I) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/cas-no-port',
      params: { hidCS008I: hidCS008I }
    })
  },
  getPickUpSet (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/pickup-set',
      params: {
        polCtrCd: params.polCtrCd,
        polPortCd: params.polPortCd
      }
    })
  },
  getGateInfoSet (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/gate-info-set',
      params: {
        polCtrCd: params.polCtrCd,
        polPortCd: params.polPortCd,
        polTrmlCd: params.polTrmlCd,
        hidCS008I: params.hidCS008I
      }
    })
  },
  getTemplateInfo (bkgNo) {
    return Send({
      method: 'get',
      url: '/trans/bookings/template/' + bkgNo
    })
  },
  insertInvoiceReq (data) {
    return Send({
      method: 'post',
      url: '/trans/invoice/' + data.blNo,
      data: {
        invRno: data.invRno,
        index: data.index,
        rtnCode: data.rtnCode
      }
    })
  }
}
